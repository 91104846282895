<template>
    <span class="navbar-left">
        <div class="navbar-brand dropdown me-2">
            <navbar-notifications :user="user" :color_notifications_icons="color_notifications_icons" />
        </div>

        <div class="navbar-brand me-2" v-if="user.doctor != null">
            <span style="display: -webkit-inline-box;" @click="this.$router.push({name: 'DoctorRequests', params: {create_prescription: true}})">
            <h4 style="margin-top: 0.2rem">
                <em :class="['ph-prescription me-3 nav-icon circle p-2', 
                {'bg-light text-dark': !this.color_notifications_icons 
                    || this.color_notifications_icons == 'light'},
                {'bg-orange-secondary text-white': this.color_notifications_icons == 'orange'}]" style="box-shadow: none"></em>
            </h4>
            </span>
        </div>

        <div class="navbar-brand dropdown">
            <span data-bs-toggle="dropdown" class="dropdown-toggle" style="display: -webkit-inline-box;">
                <h4 style="margin-top: 0.2rem">
                <em  :class="['ph-user me-3 nav-icon circle p-2', 
                {'bg-light text-dark': !this.color_notifications_icons 
                    || this.color_notifications_icons == 'light'},
                {'bg-orange-secondary text-white': this.color_notifications_icons == 'orange'}]" style="box-shadow: none"></em>
                </h4>
            </span>

            <div class="dropdown-menu">
            <span class="account">
                <p v-if="user.name" class="account-text" style="display: inline-block">Hey, {{ user.name.split(' ')[0]  }}!</p>
            </span>
            
            <router-link :to="{name: 'Profile'}" class="dropdown-item">
                <font-awesome-icon class="icon" :icon="['fa', 'user']" /> 
                <p class="dropdown-list"> My Account </p>
            </router-link>

            <router-link v-if="user.admin" :to="{name: 'AdminUsers'}" class="dropdown-item">
                <font-awesome-icon class="icon" :icon="['fa', 'crown']" /> 
                <p class="dropdown-list"> Administration </p>
            </router-link>

            <router-link :to="{name: 'DriverHome'}" class="dropdown-item" v-if="user.driver">
                <font-awesome-icon class="icon" :icon="['fa', 'car']" /> 
                <p class="dropdown-list"> Driver Home </p>
            </router-link>                

            <div class="dropdown-divider"></div>

            <a class="dropdown-item logout" @click="logout()">
                <font-awesome-icon class="icon logout" :icon="['fa', 'sign-out-alt']" />
                <p class="dropdown-list"> Logout </p>
            </a>
            </div>
        </div>     
    </span>
</template>

<style lang="scss" scoped>
    @import '../assets/scss/layout/_navbar.scss';
    
    .notification-badge {
        font-size: 11px;
        width: 20px;
        height: 20px;
        padding: 4px;
        position: absolute;
        top: 0.2rem;
        right: 0.3rem;  
    }

    .notification-dot {
        width: 10px;
        height: 10px;
        padding: 5px;
        margin-top: 7px;
        margin-right: 8px;
    }

    .nav-bottom {
        border-top-left-radius: 80%; 
        padding: 0rem 2rem 0.5rem 2rem;
        border-top-right-radius: 80%;
        z-index: 1;

        .nav-item {
        cursor: pointer;
        }
    }

    .navbar .navbar-brand .nav-icon {
        margin-right: 30px;
        font-size: 16px;
        cursor: pointer;
    }

    .dropdown-toggle::after {
        display: none;
    }

</style>

<script>
    import NavbarNotifications from './NavbarNotifications'

    export default {
        props: ['user', 'color_notifications_icons'],
        components: {
            NavbarNotifications
        },
        methods: {
            logout() {
                localStorage.removeItem('user')

                setTimeout(() => {
                    this.$router.push({name: 'Login'});
                }, 1000);
            },
        }
    }
</script>