<template>
    <div class="notification">
        <span data-bs-toggle="dropdown" class="dropdown-toggle" style="display: -webkit-inline-box;" data-bs-auto-close="outside" aria-expanded="false">
            <h4 style="margin-top: 0.2rem">
                <em :class="['ph-bell-ringing  me-3 nav-icon circle p-2', 
                {'bg-light text-dark': !this.color_notifications_icons 
                    || this.color_notifications_icons == 'light'},
                {'bg-orange-secondary text-white': this.color_notifications_icons == 'orange'}]" style="box-shadow: none"></em> 
                <span class="badge bg-dark text-light rounded-circle notification-badge">{{ total_notification }}</span>
            </h4>
        </span>

        <div class="dropdown-menu" style="width: 265px">
            <span class="account">
                <p v-if="user.name" class="account-text" style="display: inline-block">My Messages </p>
            </span>
            
            <div class="card border-0" v-for="({notification, notification_type, created_at, parameter_id, link}, index) in notifications" :key="index">
                <div @click="generateRoute(notification_type, parameter_id)">
                    <div class="card-body pt-1 pb-1 ps-3 pe-3">
                        <span class="text-orange text-uppercase" style="font-size: 12px; font-weight: 700; letter-spacing: 1px;"> <span style="font-weight: 700; font-size: 14px">&bull;</span> {{notification_type}} </span>

                        <p class="mb-0 mt-1" style="font-size: 14px; white-space: pre-line;">{{ notification }}</p>

                        <p class="text-muted mb-0 mt-1" style="font-size: 12px;"> {{ created_at.substring(0, 10)  }} {{ link }}</p>
                    </div>
                </div>
            </div>

            <div class="card border-0">
                <div class="card-body pt-2 pb-1">
                <span v-if="notification_page > 0 && notification_page < total_notification">
                    <button class="btn bg-orange-secondary text-white btn-sm w-50 fw-bold me-2" @click="getNotifications('back')">
                    <font-awesome-icon class="ms-2" :icon="['fa', 'angle-left']"></font-awesome-icon>
                    </button>

                    <button class="btn bg-orange-secondary text-white btn-sm w-50 fw-bold" @click="getNotifications('forward')">
                    <font-awesome-icon class="ms-2" :icon="['fa', 'angle-right']"></font-awesome-icon>
                    </button>
                </span>

                <button class="btn bg-orange-secondary text-white btn-sm w-100 fw-bold" @click="getNotifications('forward')" v-if="notification_page == 0">
                    <font-awesome-icon class="ms-2" :icon="['fa', 'angle-right']"></font-awesome-icon>
                </button>
                
                <button class="btn bg-orange-secondary text-white btn-sm w-100 fw-bold" @click="getNotifications('forward')" v-if="notification_page > 0 && notification_page == total_notification">
                    <font-awesome-icon class="ms-2" :icon="['fa', 'angle-right']"></font-awesome-icon>
                </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../assets/scss/layout/_navbar.scss';
    
    .notification-badge {
        font-size: 11px;
        width: 20px;
        height: 20px;
        padding: 4px;
        position: absolute;
        top: 0.2rem;
        right: 0.3rem;  
    }

    .notification-dot {
        width: 10px;
        height: 10px;
        padding: 5px;
        margin-top: 7px;
        margin-right: 8px;
    }

    .nav-bottom {
        border-top-left-radius: 80%; 
        padding: 0rem 2rem 0.5rem 2rem;
        border-top-right-radius: 80%;
        z-index: 1;

        .nav-item {
        cursor: pointer;
        }
    }

    .navbar .navbar-brand .nav-icon {
        margin-right: 30px;
        font-size: 16px;
        cursor: pointer;
    }

    .dropdown-toggle::after {
        display: none;
    }

</style>

<script>
    export default {
        props: ['user', 'color_notifications_icons'],
        data() {
            return {
                notification_page: 0
            }
        },
        computed: {
            notifications () {
                return this.$store.state.notifications
            },
            total_notification () {
                return this.$store.state.total_notification
            },
            notifications_length () {
                return this.notifications.length
            }
        },
        mounted() {
            this.getNotifications()
        },
        methods: {
            getNotifications(val) {
                if(val == 'forward') {
                    this.notification_page += 1
                } else if(val == 'back' && this.notification_page > 0) {
                    this.notification_page -= 1
                }

                this.$store.dispatch('getNotifications', this.notification_page)
            }, 
            generateRoute(notification_type, id) {
                let data = {}

                if(notification_type == 'Orders') {
                    data = {
                        name: 'MyDeliveries',
                        params: {}
                    }
                } else if (notification_type == 'Order Status') {
                    data = {
                        name: 'DeliveryStatus',
                        params: {
                            delivery_id: id
                        }
                    }
                } else if(notification_type == 'Refills') {
                    let chosen_stock = this.getChosenStock(id)
                    
                    data = {
                        name: 'DeliveryStatus',
                        params: {
                            user: id,
                            screen: 3,
                            chosen_stock: chosen_stock
                        }
                    }
                }

                this.$router.push(data)
            },
            doSearch(id) {
                this.axios.post('api/search-stock', {
                    search: id
                }).then(response => {
                    console.log(response.data.data)
                    return response.data.data
                })
            },
        }
    }
</script>